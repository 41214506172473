/*
 * @Author: your name
 * @Date: 2021-09-13 09:39:21
 * @LastEditTime: 2022-01-12 15:19:06
 * @LastEditors: your name
 * @Description: In User Settings Edit
 * @FilePath: \project\src\views\main\static\menu.ts
 */
export const AdminMenu: Array<any> = [
  {
    nav: '应用列表',
    path: '/',
    icon: 'icon-yingyong',
    title: '管理应用服务',
    id: 1,
    sub: [
      {
        nav: '应用列表',
        path: '/appList',
        id: 2,
      },
      {
        nav: '添加应用',
        path: '/addApp',
        id: 3,
      },
      {
        nav: '应用分类',
        path: '/classList',
        id: 4,
      },
    ],
  }
];