
import {
  defineComponent,
  getCurrentInstance,
  onBeforeUnmount,
  onMounted,
  reactive,
  toRefs,
  computed,
  watchEffect,
  watch,
} from "vue";
import { AdminMenu } from "./static/menu";
import { useRouter, useRoute } from "vue-router"; //引入路由
import { mainServer } from "@/apiServer/installServer";
import commonFun from "@/common/ts/commonFun";
import { ElLoading } from "element-plus";
export default defineComponent({
  name: "Main",
  setup() {
    const { proxy }: any = getCurrentInstance();
    const router = useRouter();
    const route = useRoute();
    let data = reactive({
      IsCollapse: true,
      Magnify: false,
      CurrentRouting: {
        nav: "",
        path: "",
      },
      menu: AdminMenu,
      title: "",
      loading: false,
      GlobalTabBarHeight: 10,
      LoadingObj: {},
    });
    const FnHandleOpen = function (key: any, keyPath: any) {
      console.log(key, keyPath);
    };
    const FnHandleClose = function (key: any, keyPath: any) {
      console.log(key, keyPath);
    };
    const FnChangeIsCollapse = function () {
      data.IsCollapse = !data.IsCollapse;
      debugger;
    };
    const FndefaultPath = function () {
      router.push({ path: "/appList", query: {} });
      // data.CurrentRouting.nav = "测试页1";
    };
    const FnMagnify = function () {
      data.Magnify = true;
    };
    const FnCloseMagnify = function () {
      console.log("FnCloseMagnify: 关闭放大");
      data.Magnify = false;
    };
    const FnToFeatureView = function (info: any): void {
      router.push({ path: info.path, query: {} });
      data.CurrentRouting = info;
      data.title = info.nav;
    };
    const routrName: any = computed(() => route.name);

    function FnGlobalTabBarMouseOver() {
      data.GlobalTabBarHeight = 30;
    }
    function FnGlobalTabBarMouseleave() {
      data.GlobalTabBarHeight = 10;
    }
    // watch(
    //   () => data.Magnify,
    //   (userRecover, prevuserRecover) => {
    //     FnGlobalTabBarStyle();
    //   },
    //   {
    //     deep: true,
    //   }
    // );
    onMounted(() => {
      FndefaultPath();
      // TODO: 页面里跳转页面的时候，需要在vueBUS里通信，然后再菜单中定位菜单。
      proxy.$Bus.$on("loading", (val: boolean) => {
        if (val) {
          if (!data.LoadingObj.hasOwnProperty("$attrs")) {
            data.LoadingObj = ElLoading.service({
              lock: true,
              text: "服务请求中...",
              background: "rgba(0, 0, 0, 0.4)",
            });
          }
        } else {
          (data.LoadingObj as any).close();
        }

        // data.loading = val;
      });
      console.log("proxy ====>", proxy);
    });
    onBeforeUnmount(() => {
      proxy.$Bus.$off("Magnify");
    });
    return {
      ...toRefs(data),
      proxy,
      FnHandleOpen,
      FnHandleClose,
      FnChangeIsCollapse,
      FnMagnify,
      FnCloseMagnify,
      FnToFeatureView,
      routrName,
      FnGlobalTabBarMouseOver,
      FnGlobalTabBarMouseleave,
    };
  },
});
